/* Modern styles with enhanced design */
:root {
  --primary-color: #7e22ce;
  --primary-light: #9333ea;
  --primary-dark: #6b21a8;
  --secondary-color: #c2910a;
  --secondary-light: #eab308;
  --accent-color: #15803d;
  --accent-light: #22c55e;
  --background-light: #f8fafc;
  --background-dark: #1e293b;
  --text-dark: #0f172a;
  --text-light: #64748b;
  --success: #10b981;
  --error: #ef4444;
  --card-bg: rgba(255, 255, 255, 0.95);
  --transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.05);
  --shadow-md: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  --shadow-lg: 0 25px 50px -12px rgba(0,0,0,0.25);
  --navbar-height: 80px;
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-dark);
  background-color: var(--background-light);
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

/* Modern Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-light);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, var(--primary-dark), var(--secondary-light));
}

/* Luxury Form Container */
.form-container {
  background: var(--card-bg);
  border-radius: 24px;
  box-shadow: var(--shadow-lg);
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  position: relative;
  overflow: hidden;
}

.form-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, 
    var(--primary-color),
    var(--secondary-color),
    var(--accent-color)
  );
  opacity: 0.8;
}

.form-container::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
}

.form-container:hover {
  transform: translateY(-8px) scale(1.01);
  box-shadow: 
    0 30px 60px -12px rgba(0,0,0,0.3),
    0 0 40px rgba(79, 70, 229, 0.1);
}

/* Luxury Contact Cards */
.contact-card {
  transition: var(--transition);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-blend-mode: overlay;
}

.contact-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 
    0 30px 60px -12px rgba(0,0,0,0.3),
    0 0 40px rgba(79, 70, 229, 0.1);
}

.contact-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, 
    rgba(79, 70, 229, 0.95),
    rgba(236, 72, 153, 0.95)
  );
  opacity: 0.9;
  transition: var(--transition);
}

.contact-card:hover::before {
  opacity: 0.95;
  background: linear-gradient(135deg, 
    rgba(67, 56, 202, 0.97),
    rgba(236, 72, 153, 0.97)
  );
}

/* Luxury Button Styles */
.submit-button {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  transition: var(--transition);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  padding: 15px 30px;
  border: none;
  box-shadow: 0 4px 15px rgba(79, 70, 229, 0.2);
}

.submit-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.6s;
}

.submit-button:hover::before {
  left: 100%;
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 
    0 20px 40px rgba(79, 70, 229, 0.3),
    0 0 20px rgba(79, 70, 229, 0.2);
  background: linear-gradient(135deg, var(--primary-dark), var(--secondary-color));
}

/* Luxury Form Input Styles */
.form-input {
  transition: var(--transition);
  border-radius: 16px;
}

.form-input .MuiOutlinedInput-root {
  border-radius: 16px;
  transition: var(--transition);
  background: rgba(255, 255, 255, 0.8);
}

.form-input .MuiOutlinedInput-root:hover {
  background: rgba(255, 255, 255, 0.9);
}

.form-input .MuiOutlinedInput-root.Mui-focused {
  background: white;
  box-shadow: 
    var(--shadow-md),
    0 0 0 2px rgba(79, 70, 229, 0.1);
  transform: translateY(-2px);
}

.form-input .MuiOutlinedInput-notchedOutline {
  border-color: rgba(79, 70, 229, 0.2);
  transition: var(--transition);
}

.form-input:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(79, 70, 229, 0.4);
}

.form-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
  border-width: 2px;
}

/* Luxury Animation for Messages */
.alert-animation {
  animation: luxurySlideIn 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes luxurySlideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Luxury Glass Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Luxury Page Title Animation */
.page-title {
  animation: luxuryGradientText 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color),
    var(--accent-color),
    var(--primary-color)
  );
  background-size: 300% auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

@keyframes luxuryGradientText {
  0% { background-position: 0% center; }
  50% { background-position: 150% center; }
  100% { background-position: 0% center; }
}

/* Luxury Loading Animation */
.loading-animation {
  animation: luxuryPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes luxuryPulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.95);
  }
}

/* Floating Elements Animation */
.floating {
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Particle Background */
.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, var(--primary-light), var(--secondary-light));
  border-radius: 50%;
  filter: blur(50px);
  opacity: 0.1;
  animation: particleFloat 20s infinite linear;
}

@keyframes particleFloat {
  0% { transform: translate(0, 0); }
  25% { transform: translate(10%, 10%); }
  50% { transform: translate(0, 20%); }
  75% { transform: translate(-10%, 10%); }
  100% { transform: translate(0, 0); }
}

/* Luxury Card Hover Effect */
.luxury-card-hover {
  transition: var(--transition);
  position: relative;
}

.luxury-card-hover::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: var(--transition);
}

.luxury-card-hover:hover::after {
  opacity: 1;
}

/* Gradient Background Animation */
.gradient-bg {
  background: linear-gradient(
    45deg,
    var(--primary-color),
    var(--secondary-color),
    var(--accent-color),
    var(--primary-color)
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Enhanced Alert Styles */
.MuiAlert-root {
  border-radius: 16px !important;
  box-shadow: var(--shadow-md) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.MuiAlert-standardSuccess {
  background: linear-gradient(135deg, rgba(16, 185, 129, 0.1), rgba(16, 185, 129, 0.2)) !important;
  border: 1px solid rgba(16, 185, 129, 0.2) !important;
}

.MuiAlert-standardError {
  background: linear-gradient(135deg, rgba(239, 68, 68, 0.1), rgba(239, 68, 68, 0.2)) !important;
  border: 1px solid rgba(239, 68, 68, 0.2) !important;
}

/* Enhanced Icons */
.contact-icon {
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.contact-icon svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.contact-card:hover .contact-icon {
  transform: scale(1.15) rotate(5deg);
}

/* Enhanced Typography */
.luxury-text {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
}

.luxury-text::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
  opacity: 0;
  transform: scaleX(0.8);
  transition: var(--transition);
}

.luxury-text:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

/* Enhanced Navbar Styles */
.MuiAppBar-root {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark)) !important;
  height: var(--navbar-height);
  box-shadow: var(--shadow-md) !important;
}

.MuiToolbar-root {
  height: 100% !important;
  min-height: var(--navbar-height) !important;
}

.navbar-logo {
  height: 50px !important;
  transition: var(--transition);
}

.navbar-logo:hover {
  transform: scale(1.05);
}

.MuiButton-root.navbar-link {
  font-size: 1.1rem !important;
  padding: 8px 16px !important;
  margin: 0 4px !important;
  border-radius: 8px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: var(--transition) !important;
  position: relative !important;
  overflow: hidden !important;
}

.MuiButton-root.navbar-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--secondary-color);
  transform: translateX(-100%);
  transition: var(--transition);
}

.MuiButton-root.navbar-link:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.MuiButton-root.navbar-link:hover::before {
  transform: translateX(0);
}

.MuiDrawer-paper {
  background: linear-gradient(135deg, var(--primary-dark), var(--primary-color)) !important;
  color: white !important;
  padding: 20px !important;
  min-width: 280px !important;
}

.MuiListItem-root.navbar-drawer-item {
  border-radius: 8px !important;
  margin-bottom: 8px !important;
  transition: var(--transition) !important;
}

.MuiListItem-root.navbar-drawer-item:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.MuiListItem-root.navbar-drawer-item .MuiListItemText-primary {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
}
